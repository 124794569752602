import { PiChartPieSlice, PiUserThin } from "react-icons/pi";
import { BsPersonArmsUp } from "react-icons/bs";
import { LiaUserClockSolid } from "react-icons/lia";

export const navSetup = [
  {
    title: "Organisation Masters",
    icon: <PiChartPieSlice />,

    menu: [
      {
        title: "Organisation",
        link: "/Setup/OrganisationMasters/Organisation",
      },
      {
        title: "Regions",
        link: "/Setup/OrganisationMasters/Region",
      },

      {
        title: "Departments",
        link: "/Setup/OrganisationMasters/Departments",
      },

      {
        title: "Structure Mapping",
        link: "/Setup/OrganisationMasters/Structure-mapping",
      },

      {
        title: "Designations",
        link: "/Setup/OrganisationMasters/Designations",
      },

      {
        title: "Employee",
        link: "/Setup/OrganisationMasters/Employees",
      },

      {
        title: "Employee Bulk Upload",
        link: "/Employee-Bulk-Upload",
      },

      {
        title: "Configuration",
        link: "/configuration",
      },
    ],
  },
  // {
  //   title: "Client Masters",
  //   // icon: <LiaUserClockSolid className="new-icn" />,
  //   icon: <PiChartPieSlice />,

  //   menu: [
  //     {
  //       title: "Clients",
  //       link: "/Client",
  //     },
  //     {
  //       title: "Client Overview",
  //       link: "/Client-Overview",
  //     },
  //   ],
  // },

  {
    title: "User Access Control",
    // icon: <LiaUserClockSolid className="new-icn" />,
    icon: <PiChartPieSlice />,

    menu: [
      {
        title: "Role Management",
        link: "/Setup/UserAccessControl/Role-Management",
      },

      {
        title: "Role Permissions",
        link: "/Role-Permissions",
      },
      {
        title: "User",
        link: "/Setup/UserAccessControl/User",
      },

      // {
      //   title: "User Role Assignment",
      //   link: "/UserRoleAssignment",
      // },
    ],
  },

  {
    title: "Configurations",
    // icon: <LiaUserClockSolid className="new-icn" />,
    icon: <PiChartPieSlice />,

    menu: [
      {
        title: "Configurations",
        link: "/Configurations",
      },
    ],
  },

  // {
  //   title: "Employees",
  //   icon: <LiaUserClockSolid className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Employees",
  //       link: "/Employees",
  //     },
  //   ],
  // },
  // {
  //   title: "Qr Management",
  //   icon: <PiUserThin className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Qr Management",
  //       link: "/Qrmanagement",
  //     },
  //   ],
  // },
  // {
  //   title: "Region",
  //   icon: <PiUserThin className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Region",
  //       link: "/Region",
  //     },
  //   ],
  // },
  // {
  //   title: "Departments",
  //   icon: <PiUserThin className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Departments",
  //       link: "/Departments",
  //     },
  //   ],
  // },
  // {
  //   title: "Designations",
  //   icon: <PiUserThin className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Designations",
  //       link: "/Designations",
  //     },
  //   ],
  // },
  // {
  //   title: "Structure Mapping",
  //   icon: <PiUserThin className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Structure Mapping",
  //       link: "/Structure-mapping",
  //     },
  //   ],
  // },
  // {
  //   title: "Client",
  //   icon: <PiUserThin className="new-icn" />,
  //   menu: [
  //     {
  //       title: "Client",
  //       link: "/Client",
  //     },
  //   ],
  // },
];
