import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Button } from 'rsuite'
import Cookies from 'js-cookie'

const PANDetailsOfOrganisation = ({setStep,panDetails,step}) => {
  console.log(panDetails,"getPanDetails")
  console.log("step", step);
  //const panData = JSON.parse(localStorage.getItem("panData"));
  const panData = JSON.parse(Cookies.get("panData"));
  return (
    <div>
       <div>
       <div>
      <div className="login-bg-new-img">
        <div className="login-page-modals">
          <div className="login-inner-modal modal-2 new-width">
            <div className="back-btn">
              <a
                href="#"
                className="color-light"
                onClick={() => setStep((prev) => prev - 1)}
              >
                <IoIosArrowBack/>
                Back
              </a>
            </div>
            <h3 className="mt-3">
              {" "}
              PAN Details of your
              <br /> organisation!{" "}
            </h3>
            <p className="my-3">Please confirm your details to continue.</p>
            <div className="gst-details-container">
              <div className="gst-details-left">
                <p>PAN Number</p>
                <p>PAN Status</p>
                <p>Number(as per PAN)</p>
                
              </div>
              <div className="gst-details-right">
                <p>{panDetails?.idNumber}</p> 
                <p>{panDetails?.idStatus}</p>
                <p>{panDetails?.name}</p> 

              </div>
             
            </div>
            
            <div className="text-center d-flex justify-content-center mt-3 custom-button-gap">
                

                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  // onClick={() => setStep(3)}
                  type="submit"
                  onClick={() => {
                    setStep((prev)=> prev + 1)
                  }}

                //   disabled={!gstInput} // Disable if gstInput is empty
                >
                  Confirm
                </Button>
              </div>
            
           
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default PANDetailsOfOrganisation
