import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { Button } from 'rsuite'
import moment from "moment";

const GSTDetailsOfOrganisation = ({setStep,gstDetails,step}) => {

  console.log("step", step);

    console.log(gstDetails,"getGstDetails")
  return (
    <div>
      <div>
        <div className="login-bg-new-img">
          <div className="login-page-modals">
            <div className="login-inner-modal modal-2 new-width">
              <div className="back-btn">
                <a
                  href="#"
                  className="color-light"
                  onClick={() => setStep((prev) => prev - 1)}
                >
                  <IoIosArrowBack />
                  Back
                </a>
              </div>
              <h3 className="mt-3">
                {" "}
                GST Details of your
                <br /> organisation!{" "}
              </h3>
              <p className="my-3">Please confirm your details to continue.</p>

              <div className="gst-details-container">
                <div className="gst-details-left">
                  <p>GSTIN No</p>
                  <p>GSTIN Status</p>
                  <p>Legal Name</p>
                  <p>Trade Name</p>
                  <p>Nature of Business</p>
                  <p>Nature of Business Activity</p>
                  <p>Registration Date</p>
                  <p>PAN</p>
                  <p>Place of Business</p>
                </div>
                <div className="gst-details-right">
                  {/* <p>27AABCU9603R1ZM</p>  */}
                  <p>{gstDetails?.gstin}</p>

                  <p>{gstDetails?.gstnStatus}</p>
                  <p>{gstDetails?.legalName}</p>
                  <p>{gstDetails?.tradeName}</p>
                  <p>{gstDetails?.natureOfBusiness.join(", ")}</p>
                  <p>{gstDetails?.natureOfCoreBusinessActivity}</p>
                  <p>
                    {gstDetails?.registrationDate
                      ? moment(gstDetails.registrationDate).format("DD-MM-YYYY")
                      : ""}
                  </p>
                  <p>{gstDetails?.pan}</p>
                  <p>{gstDetails?.principalPlaceOfBusiness}</p>
                </div>
              </div>
              {/* <div className="sub-table">
                    <table className="w-100">
                      <tr>
                        <td>GSTIN No</td>
                        <td>{gstDetails?.gstin}</td>
                      </tr>
                      <tr>
                        <td>GSTIN Status</td>
                        <td>{gstDetails?.gstnStatus}</td>
                      </tr>
                      <tr>
                        <td>Legal Name</td>
                        <td>{gstDetails?.legalName}</td>
                      </tr>
                      <tr>
                        <td>Trade Name</td>
                        <td>{gstDetails?.tradeName}</td>
                      </tr>

                      <tr>
                        <td>Nature of Business</td>
                        <td>{gstDetails?.natureOfBusiness.join(", ")}</td>
                      </tr>
                      <tr>
                        <td>Nature of Business Activity</td>
                        <td>{gstDetails?.natureOfCoreBusinessActivity}</td>
                      </tr>
                      <tr>
                        <td>Registration Date</td>
                        <td>{gstDetails?.registrationDate}</td>
                      </tr>
                      <tr>
                        <td>PAN</td>
                        <td>{gstDetails?.pan}</td>
                      </tr>
                      <tr>
                        <td>Place of Business</td>
                        <td>{gstDetails?.principalPlaceOfBusiness}</td>
                      </tr>
                    </table>
                  </div> */}
              <div className="text-center d-flex justify-content-center mt-3 custom-button-gap">
                <Button
                  className="btn-large mt-3 w-75"
                  appearance="primary"
                  // onClick={() => setStep(3)}
                  type="submit"
                  onClick={() => {
                    setStep((prev) => prev + 1);
                  }}

                  //   disabled={!gstInput} // Disable if gstInput is empty
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GSTDetailsOfOrganisation
