import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  Input,
  Loader,
  Message,
  SelectPicker,
  useToaster,
} from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import ConfirmDelete from "./ConfirmDelete";
import Cookies, { set } from "js-cookie";
import NotificationMessage from "../NotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { handleOnboardingStatus } from "../../Redux/userSlice";
// import Accordion,{  Placeholder } from 'rsuite';

const SetupDesignation = ({ step, setStep, refetch = () => {} }) => {
  const toaster = useToaster();
  const dispatch = useDispatch();
  const [placement, setPlacement] = React.useState("bottomEnd");
  const [newData, setNewData] = useState([]);
  const [designationParentId, setDesignationParentId] = useState(0);
  const [newDesignationArray, setNewDesignationArray] = useState([]);
  const [designationtDropDownData, setDesignationtDropDownData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(0);
  const [editedName, setEditedName] = useState("");
  const [parentIdForUpdate, setParentIdForUpdate] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState({});
  const [openItems, setOpenItems] = useState({});
  const [activeParentId, setActiveParentId] = useState(null);
  const [updatedKey, setUpdatedKey] = useState(false);
  const [isDesignationAdded, setIsDesignationAdded] = useState(false); // Track if a region has been added

  const { onboardingStatus, userData } = useSelector(
    (state) => state.rootReducer
  );
  console.log("userData redux", userData);

  console.log("step", step);
  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Designation added successfully"} </strong>
    </Message>
  );
  const addErrorMessage = (errorMessage) => (
    <Message showIcon type="error" closable>
      <strong>{errorMessage}</strong>
    </Message>
  );

  const deleteMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Designation deleted successfully"} </strong>
    </Message>
  );

  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Designation update successfully"} </strong>
    </Message>
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const {
    data: designationArray,
    isLoading: getAllDesignation,
    refetch: refetchApi,
  } = useQuery(
    ["getAllDesignation"],
    () =>
      api.get(
        `master-service/v1/customers/${userData?.customerId}/designation?page=0&size=10&sort=id,desc&treeView=true`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res.data.designations,
    }
  );

  useEffect(() => {
    if (designationArray && designationArray?.length > 0) {
      setIsDesignationAdded(true);
    }
  }, [designationArray]);

  const flattenDesignation = (designations) => {
    let flatList = [];

    const recurse = (DesignationList) => {
      DesignationList?.forEach((designation) => {
        flatList.push({ label: designation.name, value: designation.id });
        if (designation.child && designation.child.length > 0) {
          recurse(designation.child);
        }
      });
    };

    recurse(designations);
    return flatList;
  };

  const handleAddDesignation = (data) => {
    addDesignation({
      name: data?.designationName,
      customerId: userData?.customerId,
      parentId:
        designationArray == []
          ? 0
          : designationParentId != 0 && designationParentId != null
          ? designationParentId
          : 0,
    });
  };

  const { mutate: addDesignation, isLoading: addDesignationLoading } =
    useMutation(
      ["addDesignation"],
      async (body) => await api.post(`master-service/v1/designation`, body),
      {
        onSuccess: (res) => {
          if (res?.data?.statusCode === 200) {
            callSetupLogsApi({ level: "designation" });
            refetchApi();
            setDesignationParentId(0);
            reset();
            // toaster.push(addMessage, { placement, duration: 5000 });
            setUpdatedKey(true);
            setIsDesignationAdded(true);
            dispatch(handleOnboardingStatus({ designation: true }));
            const notificationKey = toaster.push(
              <NotificationMessage
                type="success"
                title="Success!"
                description={res?.data?.message}
                onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
              />,

              { placement: "bottomEnd", duration: 5000 }
            );
          } else {
            const notificationKey = toaster.push(
              <NotificationMessage
                type="error"
                title="Error!"
                description={res?.data?.message || "Something went wrong."}
                onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
              />,
              { placement: "bottomEnd", duration: 5000 }
            );
          }
        },
        onError: (err) => {
          console.log("error", err.response);
          const errorMessage = err.response?.data?.message;
          if (
            errorMessage &&
            errorMessage.includes("Designation already exists")
          ) {
            // toaster.push(addErrorMessage(errorMessage), {
            //   placement,
            //   duration: 5000,
            // });
            const notificationKey = toaster.push(
              <NotificationMessage
                type="error"
                title="Error!"
                description={errorMessage}
                onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
              />,
              { placement: "bottomEnd", duration: 5000 }
            );
          } else {
            // toaster.push(
            //   addErrorMessage(
            //     "An error occurred while adding the designation."
            //   ),
            //   { placement, duration: 5000 }
            // );
            const notificationKey = toaster.push(
              <NotificationMessage
                type="error"
                title="Error!"
                description={"An error occurred while adding the designation"}
                onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
              />,
              { placement: "bottomEnd", duration: 5000 }
            );
          }
        },
      }
    );
  const {
    mutate: updateDesignation,
    isLoading: updateDesignationLoading,
    refetch: refetchupdateDesignation,
  } = useMutation(
    ["updateDesignation"],
    async (body) => {
      console.log("Updating with body:", body);
      return await api.put(`master-service/v1/designations/${editId}`, body);
    },
    {
      onSuccess: (res) => {
        const successMessage = res.data.message;
        if (res?.data?.statusCode === 200) {
          refetchApi();
          // toaster.push(updateMessage, { placement, duration: 5000 });
          setIsEdit(false);
          setEditId(0);
          setEditedName("");
          setParentIdForUpdate(0);
          setUpdatedKey(true);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={successMessage}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
      onError: (err) => {
        console.log("error", err.response);
        const errorMessage = err.response?.data?.message;
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={errorMessage}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
          />,

          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );

  const { mutate: deleteDesignation, isLoading: deleteDesignationById } =
    useMutation(
      ["deleteDesignationById"],
      async (body) =>
        await api.delete(
          `master-service/v1/customers/${userData?.customerId}/designations/${body}`
        ),
      {
        onSuccess: (res) => {
          if (res?.data?.statusCode === 200) {
            refetchApi();
            const notificationKey = toaster.push(
              <NotificationMessage
                type="success"
                title="Success!"
                description={res?.data?.message}
                onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
              />,
              { placement: "bottomEnd", duration: 20000 }
            );
            // toaster.push(deleteMessage, { placement, duration: 5000 });
            setUpdatedKey(true);
          } else {
            const notificationKey = toaster.push(
              <NotificationMessage
                type="error"
                title="Error!"
                description={
                  res?.data?.message || "Something went wrong during deletion."
                }
                onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
              />,
              { placement: "bottomEnd", duration: 5000 }
            );
          }
        },
        onError: (err) => {
          console.log("error", err.response);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={err?.response?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        },
      }
    );

  const handleDeleteDesignation = () => {
    setOpenDeleteModal(false);
    deleteDesignation(deleteObject.id);
  };

  const {
    mutate: callSetupLogsApi,
    isLoading: callSetupLogsApiLoading,
    refetch: refetchCallSetupLogsApi,
  } = useMutation(
    ["setup-log"],
    async (body) =>
      await api.put(
        `master-service/v1/customers/${userData?.customerId}/setup-log`,
        body
      ),
    {
      onSuccess: (res) => {
        if (res?.data?.statusCode === 200) {
          // toaster.push(updateMessage, { placement, duration: 5000 });
          // setStep((prev) => prev + 1);
        }
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  // useEffect(() => {
  //   if (designationArray?.length > 0) {

  //       const flattenChildren = (childArray) => {
  //       let result = [];
  //       childArray.forEach((child) => {
  //         result.push({ id: child.id, name: child.name });
  //         if (child.child && child.child.length > 0) {
  //           result = result.concat(flattenChildren(child.child));
  //         }
  //       });
  //       return result;
  //     };

  //     const flattenData = (dataArray) => {
  //       let result = [];
  //       dataArray.forEach((item) => {
  //         result.push({ id: item.id, name: item.name });
  //         if (item.child && item.child.length > 0) {
  //           result = result.concat(flattenChildren(item.child));
  //         }
  //       });
  //       return result;
  //     };

  //     setDesignationtDropDownData(flattenData(designationArray));
  //   }
  // }, [designationArray]);
  useEffect(() => {
    setNewDesignationArray(designationArray);
  }, [designationArray]);
  useEffect(() => {
    if (updatedKey) {
      refetch();
    }
  });
  const handleUpdate = () => {
    console.log("edit id", editId);
    console.log("editedName", editedName);

    // Check if editId and editedName are valid
    if (editId && editedName) {
      const body = {
        // id: editId,
        name: editedName,
        parentId: parentIdForUpdate || 0,
        customerId: userData?.customerId,
      };
      console.log("Preparing to update with body:", body);
      updateDesignation(body);
    }
  };
  const handleBlur = () => {
    if (isEdit) {
      handleUpdate();
    }
    setIsEdit(false);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
      setIsEdit(false);
    }
  };
  const handleToggle = (id) => {
    // Find the item to determine if it's a parent or child
    const findItem = (data, targetId) => {
      for (let item of data) {
        if (item.id === targetId) {
          return item;
        }
        if (item.child) {
          const found = findItem(item.child, targetId);
          if (found) return found;
        }
      }
      return null;
    };

    const item = findItem(newDesignationArray, id);

    // If item is not found, return
    if (!item) return;
    const isParent = !item.parentId;

    if (isParent) {
      if (activeParentId !== id) {
        const newOpenItems = {};
        newOpenItems[id] = true;
        setOpenItems(newOpenItems);
        setActiveParentId(id);
      } else {
        setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
        setActiveParentId((prev) => (prev === id ? null : id));
      }
    } else {
      setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const AccordionItem = ({ item, level = 0 }) => {
    {
      console.log(item);
    }
    return (
      <Accordion.Item eventKey={item.id.toString()}>
        <Accordion.Header
          onClick={() => handleToggle(item.id)}
          className={item.child?.length > 0 ? "" : "hideIcon"}
        >
          <div className="strip mb-1" onClick={(e) => e.stopPropagation()}>
            <div className="d-flex align-items-center">
              <div>
                <p className="data-text">
                  {isEdit && editId === item.id ? (
                    <Input
                      onClick={(e) => e.stopPropagation()}
                      value={editedName}
                      onBlur={handleBlur}
                      onKeyDown={handleEnterKeyPress}
                      autoFocus={true}
                      onChange={(value) => setEditedName(value)}
                    />
                  ) : (
                    item.name
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <div>
                <FaPlus className="strip-icon" />
              </div> */}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setEditedName(item.name);
                  setIsEdit(true);
                  setEditId(item.id);
                  setParentIdForUpdate(item.parentId || 0);
                }}
              >
                <MdEdit className="strip-icon" />
              </div>
              <div
                onClick={() => {
                  setDeleteObject(item);
                  setOpenDeleteModal(true);
                }}
              >
                <TiDelete />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {item.child?.length > 0 && (
            <NestedAccordion data={item.child} level={level + 1} />
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  // Main NestedAccordion component
  const NestedAccordion = ({ data, level = 0 }) => {
    return (
      <Accordion
        activeKey={Object.keys(openItems).filter((key) => openItems[key])}
      >
        {data?.map((item) => (
          <AccordionItem key={item.id} item={item} level={level} />
        ))}
      </Accordion>
    );
  };

  return (
    <div>
      {getAllDesignation || addDesignationLoading || deleteDesignationById ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
      <div className="login-bg-new-img">
        <div className="login-page-modals new-width">
          <div className="login-inner-modal modal-5">
            <div className="back-btn">
              <a
                href="#"
                className="color-light"
                onClick={() =>
                  onboardingStatus.designation
                    ? setStep(17)
                    : setStep((prev) => prev - 1)
                }
              >
                <IoIosArrowBack />
                Back
              </a>
            </div>
            <h3 className="mt-3">Setup Designation </h3>
            <p className="my-3">Create Designation as per your organisation</p>

            <form onSubmit={handleSubmit(handleAddDesignation)}>
              <div>
                <div className="align-items-end d-flex">
                  <div className="container-fluid">
                    <div className="row align-items-end">
                      <div className="col-md-5">
                        <div className="new-margin">
                          <label>Designation Name </label>

                          <input
                            placeholder="Enter Designation name"
                            style={{ width: "150px !important" }}
                            type="text"
                            {...register("designationName", {
                              required: "Designation name is Required",
                            })}
                          />
                        </div>
                        <span className=" d-block requird-color">
                          {errors.designationName &&
                            errors.designationName.message}
                        </span>
                      </div>
                      <div className="col-md-5">
                        <div className=" new-margin">
                          <label>Parent Designation </label>
                          <SelectPicker
                            data={flattenDesignation(newDesignationArray)}
                            searchable={true}
                            style={{ width: 224 }}
                            placeholder="Select Designation"
                            value={designationParentId}
                            onChange={(val) => {
                              console.log("val", val);
                              setDesignationParentId(val);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div>
                          <Button
                            className="btn-large"
                            style={{ margin: "0 -10px" }}
                            appearance="primary"
                            type="submit"
                          >
                            Add New
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isDesignationAdded && (
                  <div>
                    <div className="organisation-structure-data">
                      <p className="data-text mb-2 mt-4">List of Designation</p>
                      <div className="scroll-container">
                        <div className="scroll-content">
                          <NestedAccordion data={newDesignationArray} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center">
                  <Button
                    className="btn-large mt-3 w-50"
                    appearance="primary"
                    onClick={() => setStep((prev) => prev + 1)}
                    // onClick={
                    //   () => callSetupLogsApi({ level: "designation" })
                    // }
                    disabled={!isDesignationAdded}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </form>
            <ConfirmDelete
              deleteObject={deleteObject}
              handledeleteObject={handleDeleteDesignation}
              setOpenDeleteModal={setOpenDeleteModal}
              openDeleteModal={openDeleteModal}
              title="Delete Confirmation"
              msg1="Deleting the parent will also delete all associated child records. Do you want to continue?"
              msg2="Are you sure you want to delete this?"
              btn1="Yes"
              btn2="No"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupDesignation;
