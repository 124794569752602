import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Button, Input, Loader, Message, SelectPicker, useToaster } from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { MdEdit } from "react-icons/md";
import Cookies from "js-cookie";
import ConfirmDelete from "./ConfirmDelete";
import NotificationMessage from "../NotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { handleOnboardingStatus } from "../../Redux/userSlice";

const SetupDepartments = ({ step, setStep,refetch=() => {}}) => {
  const toaster = useToaster();
   const dispatch = useDispatch();
  const [placement, setPlacement] = React.useState("bottomEnd");
  const [departmentParentId, setDepartmentParentId] = useState(0);
  const [newDepartmentArray, setNewDepartmentArray] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(0);
  const [editedName, setEditedName] = useState("");
  const [parentIdForUpdate, setParentIdForUpdate] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState({});
  const [openItems, setOpenItems] = useState({});
  const [activeParentId, setActiveParentId] = useState(null);
  const[updatedKey,setUpdatedKey]  = useState(false)
  const [isDepartmentAdded, setIsDepartmentAdded] = useState(false);  // Track if a region has been added

  
   const {onboardingStatus,userData} = useSelector((state) => state.rootReducer);
  console.log("userData redux", userData);

  

  console.log("step", step);
  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Department added successfully"} </strong>
    </Message>
  );
  const addErrorMessage = (errorMessage) => (
    <Message showIcon type="error" closable>
      <strong>{errorMessage}</strong>
    </Message>
  );
  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Department delete successfully"} </strong>
    </Message>
  );
  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Department update successfully"} </strong>
    </Message>
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  

  const {
    data: departmentsArray,
    isLoading: getAllDepartmentLoading,
    refetch: refetchApi,
  } = useQuery(
    ["getAllDepartments"],
    () =>
      api.get(
        `master-service/v1/customers/${userData?.customerId}/departments?page=0&size=1000&sort=id,desc&treeView=true`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res.data.departments,
    }
  );

  useEffect(() => {
    if (departmentsArray && departmentsArray.length > 0) {
      setIsDepartmentAdded(true);
    }
  },[departmentsArray]);

  const flattenDepartments = (departments) => {
    let flatList = [];

    const recurse = (departmentList) => {
      departmentList?.forEach((department) => {
        flatList.push({ label: department.name, value: department.id });
        if (department.child && department.child.length > 0) {
          recurse(department.child);
        }
      });
    };

    recurse(departments);
    return flatList;
  };
  const handleAddDepartments = (data) => {
    addDepartments({
      name: data?.departmentsName,
      customerId: userData?.customerId,
      parentId:departmentsArray == [] ? 0 : departmentParentId != 0 && departmentParentId != null ? departmentParentId : 0,

    });
  };

  const { mutate: addDepartments, isLoading: addDepartmentLoading } = useMutation(
    ["addDepartment"],
    async (body) => await api.post(`master-service/v1/department`, body),
    {
      onSuccess: (res) => {
        if(res?.data?.statusCode === 200){
          callSetupLogsApi({ level: "department" })
          refetchApi();
          reset();
          setDepartmentParentId(0);
          // toaster.push(addMessage, { placement, duration: 5000 });
          setUpdatedKey(true);
          setIsDepartmentAdded(true);
          dispatch(handleOnboardingStatus({ department: true }));
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        }else{
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
       
      },
      onError: (err) => {
        console.log("error", err.response);
        const errorMessage = err.response?.data?.message;

        if (errorMessage && errorMessage.includes("Department already exists")) {
          // toaster.push(addErrorMessage(errorMessage), {
          //   placement,
          //   duration: 5000,
          // });
          toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={errorMessage}
            />,
            { placement: "bottomEnd", duration: 5000 }
          );

        } else {
          // toaster.push(
          //   addErrorMessage("An error occurred while adding the department."),
          //   { placement, duration: 5000 }
          // );
          toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description="An error occurred while adding the department."
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
    }
  );

  useEffect(() => {
    if (departmentsArray && departmentsArray.length > 0) {
      // If regions already exist, enable the Continue button
      setIsDepartmentAdded(true);
    } else {
      // If no regions exist, disable the Continue button
      setIsDepartmentAdded(false);
    }
  }, [departmentsArray]);

  const {
    mutate: deleteDepartment,
    isLoading: deleteDepartmentLoading,
    refetch: refetchdeleteRegion,
  } = useMutation(
    ["deleteDepartment"],
    // async (body) => await api.delete(`masterRegion/web/deleteRegion/${body}`),
    async (body) => await api.delete(`master-service/v1/customers/${userData?.customerId}/departments/${body}`),
    {
      onSuccess: (res) => {
        if(res?.data?.statusCode === 200){
          
          refetchApi();
          // toaster.push(deleteMessage, { placement, duration: 5000 });
          setUpdatedKey(true);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 20000 }
          );
        }else{
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong during deletion."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
     
        // setStep(4);
      },
      onError: (err) => {
        console.log("error", err.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );
  const { mutate: updateDepartment,
    isLoading: updateRegionLoading,
    refetch: refetchupdateRegion, } = useMutation(
    ["updateDepartment"],
    async (body) => await api.put(`master-service/v1/departments/${editId}`, body),
    {
      onSuccess: (res) => {
        const successMessage = res.data.message;
        if(res?.data?.statusCode === 200){
          refetchApi();
          // toaster.push(updateMessage, { placement, duration: 5000 });
          setIsEdit(false);
          setEditId(0);
          setEditedName("");
          setParentIdForUpdate(0);
          setUpdatedKey(true);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={successMessage}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        }
    
       },
      onError: (err) => {
        console.log("error", err.response);
        const errorMessage = err.response?.data?.message;
        if (errorMessage.includes("Region already exists")) {
          toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={errorMessage}
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        } else {
          // Handle general error
          toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description="An error occurred while adding the region."
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
    }
  );

  const {
    mutate: callSetupLogsApi,
    isLoading: callSetupLogsApiLoading,
    refetch: refetchCallSetupLogsApi,
  } = useMutation(
    ["setup-log"],
    async (body) =>
      await api.put(
        `master-service/v1/customers/${userData?.customerId}/setup-log`,
        body
      ),
    {
      onSuccess: (res) => {
       
        
      },
      onError: (err) => {
        console.log("error", err.response);

      },
    }
  );

  const handleDeleteRegion = () => {
    setOpenDeleteModal(false);
    deleteDepartment(deleteObject.id);
  };

  useEffect(() => {
    setNewDepartmentArray(departmentsArray);
  }, [departmentsArray]);
 
  const handleUpdate = () => {
    console.log("editedName", editedName);

    // Check if editId and editedName are valid
    if (editId && editedName) {
      const body = {
        // id: editId,
        name: editedName,
        parentId: parentIdForUpdate || 0,
        customerId: userData?.customerId,
      };
      console.log("Preparing to update with body:", body);
      updateDepartment(body);
    }
  };

  useEffect(() => {
    if(updatedKey){
      refetch();
    }
  })

  const handleBlur = () => {
    if (isEdit) {
      handleUpdate();
    }
    setIsEdit(false);
  };


  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      setIsEdit(false);
      handleUpdate();
    }
  };

  const handleToggle = (id) => {
    // Find the item to determine if it's a parent or child
    const findItem = (data, targetId) => {
      for (let item of data) {
        if (item.id === targetId) {
          return item;
        }
        if (item.child) {
          const found = findItem(item.child, targetId);
          if (found) return found;
        }
      }
      return null;
    };

    const item = findItem(newDepartmentArray, id);
    if (!item) return;

    const isParent = !item.parentId;

    if (isParent) {
      // If clicking a different parent than the active one
      if (activeParentId !== id) {
        // Close all other accordions
        const newOpenItems = {};
        newOpenItems[id] = true;
        setOpenItems(newOpenItems);
        setActiveParentId(id);
      } else {
        // Clicking the same parent - just toggle it
        setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
        setActiveParentId((prev) => (prev === id ? null : id));
      }
    } else {
      // For child items, just toggle normally
      setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };


  const AccordionItem = ({ item, level = 0 }) => {
    return (
      <Accordion.Item eventKey={item.id.toString()}>
        <Accordion.Header
          onClick={() => handleToggle(item.id)}
          className={item.child?.length > 0 ? "" : "hideIcon"}
        >
          <div className="strip mb-1" onClick={(e) => e.stopPropagation()}>
            <div className="d-flex align-items-center">
              <div>
                <p className="data-text">
                  {isEdit && editId === item.id ? (
                    <Input
                      onClick={(e) => e.stopPropagation()}
                      value={editedName}
                      onBlur={handleBlur}
                      onKeyDown={handleEnterKeyPress}
                      autoFocus={true}
                      onChange={(value) => setEditedName(value)}
                    />
                  ) : (
                    item.name
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <div>
                <FaPlus className="strip-icon" />
              </div> */}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setEditedName(item.name);
                  setIsEdit(true);
                  setEditId(item.id);
                  setParentIdForUpdate(item.parentId || 0);
                }}
              >
                <MdEdit className="strip-icon" />
              </div>
              <div
                onClick={() => {
                  setDeleteObject(item);
                  setOpenDeleteModal(true);
                }}
              >
                <TiDelete />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {item.child?.length > 0 && (
            <NestedAccordion data={item.child} level={level + 1} />
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const NestedAccordion = ({ data, level = 0 }) => {
    return (
      <Accordion
        activeKey={Object.keys(openItems).filter((key) => openItems[key])}
      >
        {data?.map((item) => (
          <AccordionItem key={item.id} item={item} level={level} />
        ))}
      </Accordion>
    );
  };
  return (
    <div className="main-div">
      {getAllDepartmentLoading || addDepartmentLoading || deleteDepartmentLoading ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
      <div className="login-bg-new-img">
        <div className="login-page-modals new-width">
          <div className="login-inner-modal modal-5">
            <div className="back-btn">
              <a
                href="#"
                className="color-light"
                onClick={() => onboardingStatus.department ? setStep(12) : setStep((prev) => prev - 1)}
              >
                <IoIosArrowBack />
                Back
              </a>
            </div>
            <h3 className="mt-3">Setup Departments</h3>
            <p className="my-3">Create Departments as per your organisation</p>

            <form onSubmit={handleSubmit(handleAddDepartments)}>
            <div>
              <div className="align-items-end d-flex">
                <div className="container-fluid">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div className="new-margin">
                        <label>Departments Name </label>
                        <input
                          placeholder="Enter Departments name"
                          type="text"
                          {...register("departmentsName", {
                            required: "Department Name is Required",
                          })}
                        />
                      </div>
                      <span className="d-block requird-color">
                        {errors.departmentsName && errors.departmentsName.message}
                      </span>
                    </div>

                    <div className="col-md-5">
                      <div className="new-margin">
                        <label>Parent Department </label>
                        <SelectPicker
                            data={flattenDepartments(newDepartmentArray)}
                            searchable={true}
                          style={{ width: 224 }}
                          placeholder="Select Department"
                          value={departmentParentId}
                          onChange={(val) => {
                            setDepartmentParentId(val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div>
                        <Button
                          className="btn-large"
                          appearance="primary"
                          type="submit"
                        >
                          Add New
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div>
             {isDepartmentAdded && (
               <div className="organisation-structure-data">
               <p className="data-text mb-2 mt-4">List of Departments</p>
               <div className="scroll-container">
                 <div className="scroll-content">
                   <NestedAccordion data={newDepartmentArray} />
                 </div>
               </div>
             </div>
             )}
            </div>

              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-50"
                  appearance="primary"
                  onClick={() => setStep((prev) => prev + 1)}
                  // onClick={() => callSetupLogsApi({ level: "department" })}
                  disabled={!isDepartmentAdded}  // Disable button until a region is added

                >
                  Continue
                </Button>
              </div>
            </div>
            </form>
            <ConfirmDelete
            deleteObject={deleteObject}
            handledeleteObject={handleDeleteRegion}
            setOpenDeleteModal={setOpenDeleteModal}
            openDeleteModal={openDeleteModal}
            title="Delete Confirmation"
            msg1 = "Deleting the parent will also delete all associated child records. Do you want to continue?"
            msg2 = "Are you sure you want to delete this?"
            btn1="Cancel"
            btn2="Delete"
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupDepartments;
