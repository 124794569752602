import React, { useEffect, useState } from "react";
import SetupOrganisation from "../components/Onboarding/SetupOrganisation";
import GSTNumberOfOrganisation from "../components/Onboarding/GSTNumberOfOrganisation";
import ConfirmDetails from "../components/Onboarding/ConfirmDetails";
import OrganisationStructure from "../components/Onboarding/OrganisationStructure";
import SetupDepartments from "../components/Onboarding/SetupDepartments";
import SetupDesignation from "../components/Onboarding/SetupDesignation";
import SetupLevels from "../components/SetupLevels";
import SetupRegions from "../components/Onboarding/SetupRegions";
import RegionsAndDepartmentsMapping from "../components/Onboarding/RegionsAndDepartmentsMapping";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Cookies from "js-cookie";
import PANNumberOfOrganisation from "../components/Onboarding/PANNumberOfOrganisation";
import GSTDetailsOfOrganisation from "../components/Onboarding/GSTDetailsOfOrganisation";
import PANDetailsOfOrganisation from "../components/Onboarding/PANDetailsOfOrganisation";
import { useDispatch } from "react-redux";
import { handleOnboardingStatus } from "../Redux/userSlice";

const Registration = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, logout } = useAuth();
  const [step, setStep] = React.useState(1);
  const [gstDetails, setGstDetails] = useState(null); // Store GST details
  const [panDetails, setPanDetails] = useState(null); // Store GST details

  const [logsData, setLogsData] = React.useState({});

  useEffect(() => {
  let temp = Cookies?.get("logs");
    if (temp) {
      setLogsData(JSON.parse(temp));
    }
  }, []);

  console.log("logsData", logsData);

  useEffect(() => {
    if (
      logsData?.customer === true &&
      logsData?.region === false &&
      logsData?.department === false &&
      logsData?.regionDepartmentMapping === false &&
      logsData?.designation === false
    ) {
      console.log("customer");
      setStep(7);
    } else if (
      logsData?.customer === true &&
      logsData?.region === true &&
      logsData?.department === false &&
      logsData?.regionDepartmentMapping === false &&
      logsData?.designation === false
    ) {
      console.log("region");
      setStep(10);
    } else if (
      logsData?.customer === true &&
      logsData?.region === true &&
      logsData?.department === true &&
      logsData?.regionDepartmentMapping === false &&
      logsData?.designation === false
    ) {
      console.log("department");
      setStep(12);
    } else if (
      logsData?.customer === true &&
      logsData?.region === true &&
      logsData?.department === true &&
      logsData?.regionDepartmentMapping === true &&
      logsData?.designation === false
    ) {
      console.log("regionDepartmentMapping");
      setStep(15);
    } else if (
      logsData?.customer === true &&
      logsData?.region === true &&
      logsData?.department === true &&
      logsData?.regionDepartmentMapping === true &&
      logsData?.designation === true
    ) {
       navigate("/Dashboard");
    } else if(
      logsData?.customer === false &&
      logsData?.region === false &&
      logsData?.department === false &&
      logsData?.regionDepartmentMapping === false &&
      logsData?.designation === false
    ) {
      setStep(1);
    }
  }, [logsData]);

  console.log("data", data);

  // if (!user) {
  //   return <Navigate to="/login" />;
  // }

  console.log(gstDetails, "getGstDetails");

  return (
    <div>
      {(() => {
        if (step === 1) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        } else if (step === 2) {
          return (
            <GSTNumberOfOrganisation
              step={step}
              setStep={setStep}
              setGstDetails={setGstDetails}
            />
          );
        } else if (step === 3) {
          return (
            <GSTDetailsOfOrganisation
              step={step}
              setStep={setStep}
              gstDetails={gstDetails}
            />
          );
        } else if (step === 4) {
          return (
            <PANNumberOfOrganisation
              step={step}
              setStep={setStep}
              setPanDetails={setPanDetails}
            />
          );
        } else if (step === 5) {
          return (
            <PANDetailsOfOrganisation
              step={step}
              setStep={setStep}
              panDetails={panDetails}
            />
          );
        } else if (step === 6) {
          return (
            <ConfirmDetails
              step={step}
              setStep={setStep}
              panDetails={panDetails}
              gstDetails={gstDetails}
            />
          );
        } else if (step === 7) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        } else if (step === 8) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 9) {
          return <SetupRegions step={step} setStep={setStep} />;
        } else if (step === 10) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 11) {
          return <SetupDepartments step={step} setStep={setStep} />;
        } else if (step === 12) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 13) {
          return <RegionsAndDepartmentsMapping step={step} setStep={setStep} />;
        } else if (step === 14) {
          return <OrganisationStructure step={step} setStep={setStep} />;
        } else if (step === 15) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        } else if (step === 16) {
          return <SetupDesignation step={step} setStep={setStep} />;
        } else if (step === 17 || step === 18) {
          return <SetupOrganisation step={step} setStep={setStep} />;
        }
      })()}

      {/* <SetupOrganisation/>
        <GSTNumberOfOrganisation/>
        <ConfirmDetails/>
        <OrganisationStructure/>
        <OrganisationStructureTwo/>
        <SetupDepartments/>
        <SetupDesignation/> */}
      {/* <SetupLevels /> */}

      {/* <SetupDepartments/> */}
      {/* <SetupRegions/> */}
      {/* <RegionsAndDepartmentsMapping/> */}
    </div>
  );
};

export default Registration;
