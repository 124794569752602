import PeoplesIcon from "@rsuite/icons/Peoples";
import { BsFileEarmarkText } from "react-icons/bs";
import { LiaUserClockSolid } from "react-icons/lia";
import { FaHandHoldingWater } from "react-icons/fa";
import { PiChartPieSlice } from "react-icons/pi";

export const navModule = [
  {
    title: "Attendance",
    icon: <PiChartPieSlice className="new-icn" />,
    menu: [
      {
        title: "Attendance Configuration",
        link: "/AttendanceConfiguration",
      },

      // {
      //   title: "Team Attendance",
      //   link: "/Team-Attendance",
      // },

      // {
      //   title: "Manual Attendance",
      //   link: "/Manual-Attendance",
      // },

      // {
      //   title: "Attendance Approval",
      //   link: "/BulkUpload",
      // },

      {
        title: "Bulk Uploads",
        link: "/Module/Attendance/bulkUpload",
      },

      {
        title: "Workflow",
        link: "/Workflow",
      },
    ],
  },
  {
    title: "Leave",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Leave Configuration",
        link: "/LeaveConfiguration",
      },
      {
        title: "Leave Types",
        link: "/Modules/Leave/Leave-Types",
      },

      {
        title: "Leave Balance",
        link: "/Modules/Leave/leave-Balance",
      },

      // {
      //   title: "Manage Leave",
      //   link: "/Manage-Leaves",
      // },

      {
        title: "Holiday List",
        link: "/Modules/Leave/Holiday-List",
      },
      {
        title: "Bulk Upload",
        link: "/Module/Leave/bulkUpload",
      },
      // {
      //   title: "Workflow",
      //   link: "/Workflow",
      // },
      // {
      //   title: "Leave Application",
      //   link: "/leaveApplication",
      // },

      {
        title: "Leave Approvals",
        link: "/Modules/Leave/Leave-Approvals",
      },

      // {
      //   title: "Apply Leave",
      //   link: "/applyLeave",
      // },

      // {
      //   title: "Application Details",
      //   link: "/applicationDetails",
      // },
    ],
  },
  {
    title: "Payroll",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Payroll Configurations",
        link: "/PayrollConfiguration",
      },
      {
        title: "Payroll Structures",
        link: "/Modules/Payroll/PayrollStructures",
      },
      {
        title: "Component Groups",
        link: "/Modules/Payroll/Component-Groups",
      },
      {
        title: "Components",
        link: "/Modules/Payroll/Components",
      },
      {
        title: "User Structure Mapping",
        link: "/Modules/Payroll/UserStructureMapping",
      },
      {
        title: "Employee Pay Structure",
        link: "/Modules/Payroll/EmployeePayStructure",
      },
      {
        title: "Payroll Cycle",
        link: "/Modules/Payroll/PayrollCycle",
      },
      {
        title: "Employee Pay Period",
        link: "/Modules/Payroll/PayPeriod",
      },

      {
        title: "Employee Pay Slips",
        link: "/Modules/Payroll/EmployeePayslips",
      },
    ],
  },

  {
    title: "Loans",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Loan Types",
        link: "/Modules/Loans/LoanTypes",
      },
      {
        title: "Loan Request",
        link: "/Modules/Loans/LoanRequest",
      },
      {
        title: "Loan Overview",
        link: "/Modules/Loans/LoanOverview",
      },
    ],
  },
  // {
  //   title: "Advances",
  //   icon: <PiChartPieSlice className="new-icn" />,

  //   menu: [
  //     {
  //       title: "Advance Requests",
  //       link: "/AdvanceRequests",
  //     },
  //     {
  //       title: "Advance OverView",
  //       link: "/AdvanceOverView",
  //     },

  //   ],
  // },
];
