import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  Input,
  Loader,
  Message,
  SelectPicker,
  useToaster,
} from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Table } from "rsuite";
import Cookies from "js-cookie";
import NotificationMessage from "../NotificationMessage";
import { useDispatch, useSelector } from "react-redux";
import { handleOnboardingStatus } from "../../Redux/userSlice";

// import Accordion,{  Placeholder } from 'rsuite';

const RegionsAndDepartmentsMapping = ({
  step,
  setStep,
  refetch = () => {},
}) => {
  const toaster = useToaster();
  const dispatch = useDispatch();
  const [placement, setPlacement] = React.useState("bottomEnd");

  const { Column, HeaderCell, Cell } = Table;
  // const [regionsArray, setRegionsArray] = useState([]);
  // const [departmentsArray, setDepartmentsArray] = useState([]);
  const [regionId, setRegionId] = useState();
  const [dipartmentId, setDipartmentId] = useState();

  const [regionMappingDropDownData, setRegionMappingDropDownData] = useState(
    []
  );
  const [departmentMappingDropDownData, setDepartmentMappingDropDownData] =
    useState([]);
  const [updatedKey, setUpdatedKey] = useState(false);
  const [isRegionDepartmentAdded, setIsRegionDepartmentAdded] = useState(false);

  console.log("step", step);

  const { onboardingStatus, userData } = useSelector(
    (state) => state.rootReducer
  );
  console.log("userData redux", userData);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   region: '',
    //   department: '',
    // }
  });

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region added successfully"} </strong>
    </Message>
  );

  const deleteMessage = (
    <Message showIcon type={"error"} closable>
      <strong>{"Designation deleted successfully"} </strong>
    </Message>
  );

  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  const {
    data: RDData,
    isLoading: getAllRegionDepartmentMappingLoading,
    refetch: refetchRegionAndDepartment,
  } = useQuery(
    ["region-department"],
    () =>
      api.get(
        `master-service/v1/customers/${userData?.customerId}/region-department?page=0&size=10&sort=id,desc`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res?.data?.regionDepartments,
    }
  );

  useEffect(() => {
    if (RDData?.length > 0) {
      setIsRegionDepartmentAdded(true);
    }
  }, [RDData]);

  const { data: regionsArray, isLoading: ipLoadingRegionLoading } = useQuery(
    ["getAllRegionInDepartmentMapping"],
    () =>
      api.get(
        `master-service/v1/customers/${userData?.customerId}/region?page=0&size=1000&sort=id,desc`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res?.data?.regions,
    }
  );

  const { data: departmentsArray, isLoading: ipLoadingDepartmentsLoading } =
    useQuery(
      ["getAllDepartments"],
      () =>
        api.get(
          `master-service/v1/customers/${userData?.customerId}/departments?page=0&size=10&sort=id,desc`
        ),
      {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
        refetchOnReconnect: false,
        enabled: true,
        select: (res) => res?.data?.departments,
      }
    );

  const handleRegionAndDepartment = (data) => {
    addRD({
      regionId: regionId,
      departmentId: dipartmentId,
    });
  };

  const { mutate: addRD, isLoading } = useMutation(
    ["addRegionDepartmentMapping"],
    async (body) => await api.post(`master-service/v1/region-department`, body),
    {
      onSuccess: (res) => {
        if (res?.data?.statusCode === 200) {
          callSetupLogsApi({ level: "regionDepartmentMapping" });
          setRegionId(0);
          setDipartmentId(0);
          refetchRegionAndDepartment();
          // toaster.push(addMessage, { placement, duration: 5000 });
          setUpdatedKey(true);
          setIsRegionDepartmentAdded(true);
          dispatch(handleOnboardingStatus({ structureMapping: true }));
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        } else {
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
      onError: (err) => {
        console.log("error", err.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );

  const {
    mutate: deleteStructureMapping,
    isLoading: deleteStructureMappingLoading,
  } = useMutation(
    ["deleteStructureMapping"],
    async (body) =>
      await api.delete(`master-service/v1/region-departments/${body}`),
    {
      onSuccess: (res) => {
        if (res?.data?.statusCode === 200) {
          refetchRegionAndDepartment();
          // toaster.push(deleteMessage, { placement, duration: 5000 });
          setUpdatedKey(true);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 20000 }
          );
        } else {
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={
                res?.data?.message || "Something went wrong during deletion."
              }
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
      onError: (err) => {
        console.log("error", err.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );

  useEffect(() => {
    if (regionsArray?.length > 0) {
      const flattenChildren = (childArray) => {
        let result = [];
        childArray.forEach((child) => {
          result.push({ id: child.id, name: child.name });
          if (child.child && child.child.length > 0) {
            result = result.concat(flattenChildren(child.child));
          }
        });
        return result;
      };

      const flattenData = (dataArray) => {
        let result = [];
        dataArray.forEach((item) => {
          result.push({ id: item.id, name: item.name });
          if (item.child && item.child.length > 0) {
            result = result.concat(flattenChildren(item.child));
          }
        });
        return result;
      };
      setRegionMappingDropDownData(flattenData(regionsArray));
    }
  }, [regionsArray]);

  useEffect(() => {
    if (updatedKey) {
      refetch();
    }
  });

  useEffect(() => {
    if (departmentsArray?.length > 0) {
      const flattenChildren = (childArray) => {
        let result = [];
        childArray.forEach((child) => {
          result.push({ id: child.id, name: child.name });
          if (child.child && child.child.length > 0) {
            result = result.concat(flattenChildren(child.child));
          }
        });
        return result;
      };

      const flattenData = (dataArray) => {
        let result = [];
        dataArray.forEach((item) => {
          result.push({ id: item.id, name: item.name });
          if (item.child && item.child.length > 0) {
            result = result.concat(flattenChildren(item.child));
          }
        });
        return result;
      };

      setDepartmentMappingDropDownData(flattenData(departmentsArray));
    }
  }, [departmentsArray]);

  const {
    mutate: callSetupLogsApi,
    isLoading: callSetupLogsApiLoading,
    refetch: refetchCallSetupLogsApi,
  } = useMutation(
    ["setup-log"],
    async (body) =>
      await api.put(
        `master-service/v1/customers/${userData?.customerId}/setup-log`,
        body
      ),
    {
      onSuccess: (res) => {
        // toaster.push(updateMessage, { placement, duration: 5000 });
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  return (
    <div>
      {getAllRegionDepartmentMappingLoading ||
      ipLoadingRegionLoading ||
      deleteStructureMappingLoading ||
      ipLoadingDepartmentsLoading ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
      <div className="login-bg-new-img">
        <div className="login-page-modals">
          <div className="login-inner-modal modal-5">
            <div className="back-btn">
              <a
                href="#"
                className="color-light"
                onClick={() =>
                  onboardingStatus.structureMapping
                    ? setStep(14)
                    : setStep((prev) => prev - 1)
                }
              >
                <IoIosArrowBack />
                Back
              </a>
            </div>
            <h3 className="mt-3">Setup Structure Mapping </h3>
            <p className="my-3">Create mapping as per your organisation.</p>

            <div>
              <div className="align-items-end d-flex">
                <div className="container-fluid p-0">
                  <div className="row align-items-end">
                    <div className="col-md-5">
                      <div>
                        <label>Regions </label>

                        <SelectPicker
                          data={regionMappingDropDownData?.map((item) => {
                            return { label: item.name, value: item.id };
                          })}
                          searchable={true}
                          style={{ width: 224 }}
                          placeholder="Select Region"
                          // {...register("region", {
                          //   // required: "Region is Required",
                          // })}
                          value={regionId}
                          onChange={(val) => {
                            setRegionId(val);
                          }}
                        />
                      </div>
                      <span className=" d-block requird-colr">
                        {errors.region && errors.region.message}
                      </span>
                    </div>
                    <div className="col-md-5">
                      <div>
                        <label>Department </label>
                        <SelectPicker
                          data={departmentMappingDropDownData?.map((item) => {
                            return { label: item.name, value: item.id };
                          })}
                          searchable={true}
                          style={{ width: 224 }}
                          placeholder="Select Department"
                          // {...register("department", {
                          //   // required: "Department is Required",
                          // })}
                          value={dipartmentId}
                          onChange={(val) => {
                            setDipartmentId(val);
                          }}
                        />
                      </div>
                      <span className=" d-block requird-colr">
                        {errors.department && errors.department.message}
                      </span>
                    </div>
                    <div className="col-md-2">
                      <div>
                        <Button
                          className="btn-large"
                          appearance="primary"
                          // type="submit"
                          onClick={() => handleRegionAndDepartment()}
                        >
                          Add New
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isRegionDepartmentAdded && (
                <div>
                  <div className="organisation-structure-data">
                    <p className="data-text mb-2 mt-4">List of mapping</p>

                    <div className="scroll-container">
                      <Table
                        className="modal-table"
                        height={300}
                        data={RDData}
                        autoHeight
                        bordered
                        cellBordered
                      >
                        <Column flexGrow={1} align="center">
                          <HeaderCell>So.no</HeaderCell>
                          <Cell>{(rowData, rowIndex) => rowIndex + 1}</Cell>
                        </Column>
                        <Column flexGrow={1} align="center">
                          <HeaderCell>Region</HeaderCell>
                          <Cell>{(rowData) => rowData.region.name}</Cell>
                        </Column>
                        <Column flexGrow={1} align="center">
                          <HeaderCell>Department</HeaderCell>
                          <Cell>{(rowData) => rowData.department.name}</Cell>
                        </Column>
                        <Column flexGrow={1} align="end">
                          <HeaderCell></HeaderCell>
                          <Cell className="left-icon">
                            {(rowData) => (
                              <div
                                onClick={() =>
                                  deleteStructureMapping(rowData.id)
                                }
                              >
                                <TiDelete />
                              </div>
                            )}
                          </Cell>
                        </Column>
                      </Table>
                    </div>
                  </div>
                </div>
              )}

              <div className="text-center">
                <Button
                  className="btn-large mt-3 w-50"
                  appearance="primary"
                  onClick={() => setStep((prev) => prev + 1)}
                  // onClick={() =>
                  //    callSetupLogsApi({ level: "regionDepartmentMapping" })
                  // }
                  disabled={!isRegionDepartmentAdded} // Disable button until a region is added
                >
                  Continue
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionsAndDepartmentsMapping;
