import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import ProtectedLayout from "./components/ProtectedLayout";
import HomeLayout from "./components/HomeLayout";
import { Loader } from "rsuite";
import Registration from "./pages/Registration";
import Cookies from "js-cookie";

import { Provider } from "react-redux";
import store from "./Redux/sotre";

const loading = (
  <div className="fullLoader">
    <Loader />
  </div>
);
const Home = lazy(() => import("./pages/LandingPage"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Login = lazy(() => import("./pages/Login"));
const OrganizationDetails = lazy(() => import("./pages/OrganisationDetails"));
const DesignationNew = lazy(() => import("./pages/DesignationNew"));
const ReportingStructure = lazy(() => import("./pages/ReportingStructure"));
const Dashboard = lazy(() => import("./pages/DashboardNew"));
function App() {
  return (
    <Suspense fallback={loading}>
      <Provider store={store}>
        <Routes>
          <Route element={<HomeLayout />}>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/" element={<Dashboard />} /> */}
          </Route>
          <Route path="/home" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/organisationDetails"
            element={<OrganizationDetails />}
          />

          <Route path="/Registration" element={<Registration />} />
          <Route path="/designationNew" element={<DesignationNew />} />
          <Route path="/reportingStructure" element={<ReportingStructure />} />
          <Route path="*" element={<ProtectedLayout />} />
        </Routes>
      </Provider>
    </Suspense>
  );
}

export default App;
